import initScrollReveal from "./scripts/scrollReveal";
import initTiltEffect from "./scripts/tiltAnimation";
import { targetElements, defaultProps } from "./data/scrollRevealConfig";

initScrollReveal(targetElements, defaultProps);
initTiltEffect();

let rnd='<span class="text-color-main">randomdevs</span>'

title=document.getElementsByClassName("hero-title")[0]
title.onmouseover=function(){title.innerHTML="You are "+rnd};
title.onmouseleave=function(){title.innerHTML="We are "+rnd};
